






















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { NumberType } from '../../../models/filters/number-filter.model';
import { convertDateFieldToAPIFormatWithoutTime } from '@/utils/date.util';

import moment from 'moment';

@Component({
  components: { Fragment }
})
export default class NumberFilter extends Vue {
  filterField = NumberType.TrailNumber;
  number = null;
  convertDateFieldToAPIFormatWithoutTime = convertDateFieldToAPIFormatWithoutTime;
  maxDate = null;

  numberTypes = [
    { value: NumberType.TrailNumber, text: 'Trailer No' },
    { value: NumberType.OrderNumber, text: 'WO No' },
    { value: NumberType.ReferenceNumber, text: 'REF#' },
    { value: NumberType.PONumber, text: 'PO#' },
    { value: NumberType.MasterBillOfLading, text: 'MBL#' },
    { value: NumberType.BillTo, text: 'Bill To' },
    { value: NumberType.PulloutLocation, text: 'P/OUT' },
    { value: NumberType.DeliveryLocation, text: 'Delivery' },
    { value: NumberType.ReturnLocation, text: 'Return' }
  ];

  created() {}

  fieldChange() {
    this.$emit('numberChanged', {
      field: this.filterField,
      value: this.number
    });
  }
}
