import { TableHeaders } from './../../../../shared/components/table/models/tableHeaders.model';
import {
  isLessThenToday,
  scheduleHandler
} from './../../../../shared/components/helpers/datePickerHandlers';
import { DATE_API_FORMAT } from '@/utils/date.util';
import moment from 'moment';

import { TopTableHeaders } from '../../models/cellRender.model';
import { CellContentType } from '@/shared/components/table/models';
import { DropLiveType, LocationType } from '@/shared/models/woSchedule.model';
import { setPoolDisabled } from '../../utils/set-chaiss-pool';
import { dropLiveOptions } from '../../models/selectsOptions';

const detailPart: TopTableHeaders = {
  key: 'detail',
  label: '',
  opportunityСhange: false,
  maxColspan: 3,
  minColspan: 1
};

const equipmentPart: TopTableHeaders = {
  key: 'eq',
  label: 'Equipment',
  class: 'equipment-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 6,
  minColspan: 1
};

const infoPart: TopTableHeaders = {
  key: 'info',
  label: 'Info',
  class: 'info-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 3,
  minColspan: 1
};

const locationPart: TopTableHeaders = {
  key: 'loc',
  label: 'Location',
  class: 'location-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 3,
  minColspan: 1
};

const geofencePart: TopTableHeaders = {
  key: 'geo',
  label: 'Geofence',
  class: 'geofence-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 3,
  minColspan: 1
};

const savePart: TopTableHeaders = {
  key: 'save',
  label: '',
  opportunityСhange: false,
  maxColspan: 3,
  minColspan: 1
};

export const ImportTableTopTypes: { [key: string]: TopTableHeaders[] } = {
  unavailable: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    {
      key: 'eta',
      label: '',
      opportunityСhange: false,
      maxColspan: 6,
      minColspan: 1
    },
    {
      key: 'sch',
      label: 'Schedule',
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 6,
      minColspan: 1
    },
    { ...savePart }
  ],

  available: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    {
      key: 'lfd',
      label: '',
      opportunityСhange: false,
      maxColspan: 2,
      minColspan: 1
    },
    {
      key: 'sch',
      label: 'Schedule',
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 7,
      minColspan: 1
    },
    { ...savePart }
  ],

  delivery: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    { ...geofencePart },
    {
      key: 'rfd',
      label: '',
      opportunityСhange: false,
      maxColspan: 2,
      minColspan: 1
    },
    {
      key: 'sch',
      label: 'Schedule',
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 11,
      minColspan: 1
    },
    { ...savePart }
  ],

  return: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    { ...geofencePart },
    {
      key: 'rfd',
      label: '',
      opportunityСhange: false,
      maxColspan: 2,
      minColspan: 1
    },
    {
      key: 'sch',
      label: 'Schedule',
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 11,
      minColspan: 1
    },
    {
      key: 'save',
      label: '',
      opportunityСhange: false,
      maxColspan: 3,
      minColspan: 1
    }
  ]
};

const detailHeaderPart = [
  { key: 'number', label: 'NO', tdClass: 'min-w-20' },
  { key: 'orderNumber', label: 'WO #', tdClass: 'min-w-100' },
  { key: 'billTo', label: 'Bill To', tdClass: 'min-w-80' }
];

const equipmentHeaderPart: (pools) => TableHeaders[] = pools => [
  { key: 'containerNumber', label: 'CONT #', tdClass: 'min-w-80' },
  {
    key: 'shippingLine',
    label: 'SSL',
    rendererKey: 'eq',
    visible: true,
    tdClass: 'min-w-30'
  },
  {
    key: 'containerSize',
    label: 'SZ',
    rendererKey: 'eq',
    visible: true,
    tdClass: 'min-w-30'
  },
  {
    key: 'chassisNumber',
    label: 'Chassis #',
    rendererKey: 'eq',
    visible: true,
    content: {
      type: CellContentType.Text,
      validators: { name: 'regular', value: '^[a-zA-Z]{4}[0-9]{6}$' },
      validatorMessage:
        'This field is a required and must have 4 letters and 6 digits'
    },
    onChange: item => setPoolDisabled(item, pools),
    tdClass: 'min-w-130'
  },
  {
    key: 'chassisPool',
    label: 'POOL',
    rendererKey: 'eq',
    visible: true,
    content: {
      type: CellContentType.List,
      list: pools,
      validators: { name: 'requiredIf', value: 'chassisNumber' },
      validatorMessage: 'This field is a required'
    },
    tdClass: 'min-w-80'
  },
  {
    key: 'bondEntry',
    label: 'BOND',
    rendererKey: 'eq',
    visible: true,
    tdClass: 'min-w-60'
  }
];

const infoHeaderPart = [
  { key: 'referenceNumber', label: 'REF', tdClass: 'min-w-80' },
  {
    key: 'poNumber',
    label: 'PO',
    rendererKey: 'info',
    visible: true,
    tdClass: 'min-w-80'
  },
  {
    key: 'masterBillOfLading',
    label: 'M B/L',
    rendererKey: 'info',
    visible: true,
    tdClass: 'min-w-90'
  }
];

const locationHeaderPart = [
  { key: 'pulloutLocation', label: 'P/OUT', tdClass: 'min-w-80' },
  {
    key: 'deliveryLocation',
    label: 'DILV',
    rendererKey: 'loc',
    tdClass: 'min-w-80'
  },
  {
    key: 'returnLocation',
    label: 'RTN',
    rendererKey: 'loc',
    visible: true,
    tdClass: 'min-w-80'
  }
];

const geofenceHeaderPart = [
  { key: 'originGeoferce', label: 'Orig', tdClass: 'min-w-80' },
  {
    key: 'currGeoferce',
    label: 'Curr',
    rendererKey: 'geo',
    visible: true,
    tdClass: 'min-w-80'
  },
  {
    key: 'dest',
    label: 'Dest',
    rendererKey: 'geo',
    visible: true,
    tdClass: 'min-w-80'
  }
];

const saveHeaderPart = [
  {
    key: 'save',
    label: 'Save',
    content: {
      type: CellContentType.Button,
      label: 'Save'
    },
    tdClass: 'min-w-60'
  },
  {
    key: 'remarks',
    label: 'Remark',
    content: {
      type: CellContentType.Text,
      skipValidation: true
    }
  }
];

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}

function getTime(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('hh:mm')
    : schedule;
}

export const ImportTableTypes = pools => {
  return {
    unavailable: [
      ...detailHeaderPart,
      ...equipmentHeaderPart(pools),
      ...infoHeaderPart,
      ...locationHeaderPart,

      { key: 'eta', label: 'ETA', formatter: getDay },
      {
        key: 'lastFreeDay',
        label: 'LFD',
        formatter: getDay,
        content: {
          type: CellContentType.Date,
          validators: true,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'avaDsc',
        label: 'DSC',
        content: {
          type: CellContentType.Checkbox
        },
        tdClass: 'min-w-40'
      },
      {
        key: 'avaObl',
        label: 'OBL',
        content: {
          type: CellContentType.Checkbox
        },
        tdClass: 'min-w-40'
      },
      {
        key: 'avaCus',
        label: 'CUS',
        content: {
          type: CellContentType.Checkbox
        },
        tdClass: 'min-w-40'
      },
      {
        key: 'avaOth',
        label: 'OTH',
        content: {
          type: CellContentType.Checkbox
        },
        tdClass: 'min-w-40'
      },

      {
        key: 'pulloutSchedule',
        label: 'P/OUT S Date',
        formatter: getDay,
        handler: scheduleHandler,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pulloutScheduleTime',
        label: 'Time',
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pulloutDriver',
        label: 'DRV ID',
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.PULLOUT,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliverySchedule',
        label: 'DILV S Date',
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,
        handler: scheduleHandler,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryScheduleTime',
        label: 'Time',
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryDriver',
        label: 'DRV ID',
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.DELIVERY,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },

      ...saveHeaderPart
    ],

    available: [
      ...detailHeaderPart,
      ...equipmentHeaderPart(pools),
      ...infoHeaderPart,
      ...locationHeaderPart,

      {
        key: 'lastFreeDay',
        label: 'LFD',
        formatter: getDay,
        content: {
          type: CellContentType.Date,
          validators: true,
          skipValidation: true
        },
        tdClass: 'min-w-130',
        handler: isLessThenToday(
          'LFD is earlier than current date. Are you sure?'
        ),

        onChange: item => {
          item.lastFreeDayCount = moment(
            item.lastFreeDay,
            DATE_API_FORMAT
          ).diff(moment().startOf('day'), 'days');

          return { ...item };
        }
      },
      { key: 'lastFreeDayCount', label: 'LFD DAY', tdClass: 'min-w-40' },

      {
        key: 'pulloutSchedule',
        label: 'P/OUT S Date',
        formatter: getDay,
        handler: scheduleHandler,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pulloutScheduleTime',
        label: 'Time',
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pulloutDriver',
        label: 'DRV ID',
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.PULLOUT,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliverySchedule',
        label: 'DILV S Date',
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,

        handler: scheduleHandler,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryScheduleTime',
        label: 'Time',
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryDriver',
        label: 'DRV ID',
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.DELIVERY,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'dropLive',
        label: 'LIVE/DRP',
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.List,
          list: dropLiveOptions
        },
        tdClass: 'min-w-130'
      },

      ...saveHeaderPart
    ],

    delivery: [
      ...detailHeaderPart,
      ...equipmentHeaderPart(pools),
      ...infoHeaderPart,
      ...locationHeaderPart,
      ...geofenceHeaderPart,
      {
        key: 'returnFreeDay',
        label: 'RFD',
        disabled: true,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130',
        onChange: item => {
          item.returnFreeDayCount = moment(
            item.returnFreeDay,
            DATE_API_FORMAT
          ).diff(moment().startOf('day'), 'days');

          return { ...item };
        }
      },
      { key: 'returnFreeDayCount', label: 'RFD DAY', tdClass: 'min-w-40' },

      {
        key: 'pulloutActualIn',
        label: 'P/OUT Date',
        disabled: true,
        formatter: getDay,
        tdClass: 'min-w-130'
      },
      {
        key: 'pulloutActualInTime',
        label: 'Time',
        disabled: true,
        rendererKey: 'sch',
        visible: true,
        tdClass: 'min-w-130'
        // formatter: getTime
      },
      {
        key: 'pulloutDriver',
        label: 'DRV ID',
        disabled: true,
        rendererKey: 'sch',
        visible: true,
        tdClass: 'min-w-130'
      },
      {
        key: 'deliverySchedule',
        label: 'DILV S Date',
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,

        handler: scheduleHandler,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryScheduleTime',
        label: 'Time',
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryDriver',
        label: 'DRV ID',
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.DELIVERY,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'dropLive',
        label: 'LIVE/DRP',
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.List,
          list: dropLiveOptions
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'emptyConfirmation',
        label: 'Empty CFM',
        rendererKey: 'sch',
        visible: true,
        tdClass: 'min-w-130',
        formatter: getDay
      },
      {
        key: 'pickupSchedule',
        label: 'P/U S Date',
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,
        content: {
          type: CellContentType.Date,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pickupScheduleTime',
        label: 'Time',
        rendererKey: 'sch',
        formatter: getTime,
        visible: true,
        content: {
          type: CellContentType.Time,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pickupDriver',
        label: 'DRV ID',
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.PICKUP,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },

      ...saveHeaderPart
    ],

    return: [
      ...detailHeaderPart,
      ...equipmentHeaderPart(pools),
      ...infoHeaderPart,
      ...locationHeaderPart,
      ...geofenceHeaderPart,
      {
        key: 'returnFreeDay',
        label: 'RFD',
        disabled: true,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        onChange: item => {
          item.returnFreeDayCount = moment(
            item.returnFreeDay,
            DATE_API_FORMAT
          ).diff(moment().startOf('day'), 'days');

          return { ...item };
        }
      },
      { key: 'returnFreeDayCount', label: 'RFD DAY' },

      {
        key: 'pulloutSchedule',
        label: 'P/OUT Date',
        disabled: true,
        formatter: getDay,
        tdClass: 'min-w-130'
      },
      {
        key: 'deliverySchedule',
        label: 'DILV Date',
        disabled: true,
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryScheduleTime',
        label: 'Time',
        disabled: true,
        rendererKey: 'sch',
        visible: true,
        formatter: getTime,
        tdClass: 'min-w-130'
      },
      {
        key: 'dropLive',
        label: 'LIVE/DRP',
        rendererKey: 'sch',
        visible: true,
        disabled: true,
        tdClass: 'min-w-130'
      },
      {
        key: 'emptyConfirmation',
        label: 'Empty CFM',
        rendererKey: 'sch',
        visible: true,
        tdClass: 'min-w-130',
        formatter: getDay
      },
      {
        key: 'pickupSchedule',
        label: 'P/U S Date',
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,
        content: {
          type: CellContentType.Date,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pickupScheduleTime',
        label: 'Time',
        rendererKey: 'sch',
        formatter: getTime,
        visible: true,
        content: {
          type: CellContentType.Time,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pickupDriver',
        label: 'DRV ID',
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.PICKUP,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'returnSchedule',
        label: 'RTN S Date',
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'returnScheduleTime',
        label: 'Time',
        rendererKey: 'sch',
        formatter: getTime,
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'returnDriver',
        label: 'DRV ID',
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.RETURN,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },

      {
        key: 'save',
        label: 'Save',
        content: {
          type: CellContentType.Button,
          label: 'Save'
        },
        tdClass: 'min-w-60'
      },
      {
        key: 'complete',
        label: 'Complete',
        content: {
          type: CellContentType.Button,
          label: 'Complete'
        },
        tdClass: 'min-w-60'
      },
      {
        key: 'remarks',
        label: 'Remark',
        content: {
          type: CellContentType.Text,
          skipValidation: true
        }
      }
    ]
  };
};
